export const OPERATIONS = {
  load_feed: 'Загрузка фида поставщика',
  set_division_and_vendor_code: 'Обновление дивизионов и кодов производителя',
  manual_retail_prices: 'Загрузка ручных розничных цен',
  add_listings: 'Добавление товаров в листинги',
  activate_listings: 'Активировать товары в листинге',
  deactivate_listings: 'Деактивировать товары в листинге',
  remove_warehouse_retail_prices: 'Удалить ручные розничные цены поставщиков',
  update_listing_categories: 'Обновить категории в листинге',
  update_canonical_categories: 'Обновить каноничные категории у каноничных товаров',
  bind_canonical_categories: 'Связать каноничные категории с категориями поставщиков',
  merge_canonical_product_duplicates: 'Объединить дубликаты каноничных товаров',
};

export const RERUN_STATUSES = [
  'done',
  'stopped',
  'error',
];

export const STOP_STATUSES = [
  'pending',
  'working',
];
