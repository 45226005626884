<template>
  <v-container fluid>
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <div class="d-flex align-baseline">
          <v-btn class="operations__btn" color="primary" depressed text @click.native="$router.push('/operations')">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="operations__title" v-text="`Лог операции «${title}»`" />
          <div class="caption text--secondary ml-4" v-text="jobErrorsCount" v-if="jobErrorsCount" />
        </div>
      </v-toolbar>
    </v-col>
    <v-col cols="12 pa-0">
      <JobErrorsList :page="page" :items-per-page="itemsPerPage" />
    </v-col>
    <v-row class="my-2" justify="center" v-if="pageCount">
      <v-col offset="4" cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
      <v-col offset="4" cols="1">
        <v-select v-model="itemsPerPage" label="Количество строк" :items="itemsPerPageSelect" :menu-props="{ top: true, offsetY: true }" outlined dense />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { OPERATIONS } from '@/helpers/operations';
import PaginationMixin from '@/mixins/PaginationMixin';
import JobErrorsList from '@/components/Operations/JobErrorsList';

export default {
  name: 'OperationsLog',
  components: {
    JobErrorsList,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      itemsPerPageSelect: [12, 24, 48, 60],
      showCreateForm: false,
    };
  },
  metaInfo() {
    return {
      title: 'Массовые операции — Лог операции — Инвентарь',
    };
  },
  computed: {
    ...mapState('operations', ['job', 'jobErrors', 'jobErrorsCount']),
    pageCount() {
      return Math.ceil(this.jobErrorsCount / this.itemsPerPage);
    },
    jobId() {
      return this.$route.params.jobId;
    },
    title() {
      if (!this.job) return '';
      return OPERATIONS[this.job.operation_slug] ?? '';
    },
  },
  watch: {
    jobId: {
      immediate: true,
      deep: true,
      async handler(val) {
        try {
          await Promise.all([
            this.FETCH_OWNERS(),
            this.FETCH_REGIONS(),
          ]);
          this.FETCH_JOB(val);
        } catch (e) {
          if (!e.isAxiosError) throw e;
        }
      },
    },
  },
  methods: {
    ...mapActions('operations', ['FETCH_JOB']),
    ...mapActions('reference', ['FETCH_OWNERS', 'FETCH_REGIONS']),
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  padding-bottom: 16px;
  padding-left: 0;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);
  z-index: 5;
}

.operations {
  &__title {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__btn {
    border-radius: 6px;
    background-color: rgba(28, 78, 255, 0.08);
    font-weight: 400;
    margin-right: 24px;
  }
}

</style>
