<template>
  <div>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </div>
    <v-col cols="12" class="my-15 text-center" v-else-if="hasError">
      Что-то пошло не так, попробуйте обновить страницу или <router-link to="/">перейти на главную</router-link>
    </v-col>
    <div class="py-16 text-center" v-else-if="!jobErrors.length">
      Здесь ничего нет
    </div>
    <v-simple-table class="brands-list" v-else>
      <template v-slot:default>
        <thead>
          <tr class="brands-list__header">
            <th class="text-left" v-text="'Номер строки'" width="10%" />
            <th class="text-left" v-text="'Описание ошибки'" />
          </tr>
        </thead>
        <tbody>
          <tr class="jobs-list-row" v-for="item in jobErrors" :key="item.id">
            <td class="text-left text-caption" v-text="item.line_number" />
            <td class="text-left text-caption" v-text="item.error" />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'JobErrorsList',
  props: {
    page: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapState('operations', ['jobErrors']),
    queryForItemsParams() {
      return { ...this.$route.query, page_size: this.itemsPerPage };
    },
    jobId() {
      return this.$route.params.jobId;
    },
  },
  watch: {
    queryForItemsParams: {
      immediate: true,
      deep: true,
      async handler(params) {
        this.isLoading = true;
        this.hasError = false;

        try {
          await this.FETCH_JOB_ERRORS({ jobId: this.jobId, params });
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('operations', ['FETCH_JOB_ERRORS']),
  },
};
</script>

<style>

</style>
